.dropdown-item.level_3 {
    padding-left: $dropdown-item-padding-x + (1 * ($dropdown-item-padding-x / 2));
}

.dropdown-item.level_4 {
    padding-left: $dropdown-item-padding-x + (2 * ($dropdown-item-padding-x / 2));
}

.dropdown-item.level_5 {
    padding-left: $dropdown-item-padding-x + (3 * ($dropdown-item-padding-x / 2));
}

#header {

    .custom-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(51, 51, 51, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .custom-toggler .navbar-toggler {
        border-color: map-get($map: $theme-colors, $key: 'dark');
        border-width: 0;
    }

    .navbar {
        background: map-get($theme-colors, 'light');
    }

    .navbar-brand {
        margin-right: 60px;

        svg,
        img {
            width: 183px;
            height: auto;

            @include media-breakpoint-down(md) {
                max-width: 110px;
                margin-left: $grid-gutter-width / 2;
            }
        }
    }

    .nav-link {
        font-size: 2rem;
        font-weight: 400;
        line-height: 3.0rem;

        color: $body-color;

        outline: none;
    }

    .nav-link {

        span {
            padding-bottom: 5px;
            white-space: nowrap;
            border-bottom: 2px solid transparentize(map-get($theme-colors, 'dark'), 1);
            transition: border 350ms ease;
        }

        &:hover,
        &:focus,
        &:active,
        &.active,
        &.trail {
            outline: none;

            span {
                padding-bottom: 5px;
                border-bottom: 2px solid transparentize(map-get($theme-colors, 'dark'), 0);
            }
        }
    }

    .dropdown-item {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 3.0rem;

        color: $body-color;

        outline: none;

        &:hover,
        &:focus,
        &:active,
        &.active,
        &.trail {
            outline: none;
            @include gradient-bg($gray-100);
        }
    }

    .dropdown-menu {
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    }

    .navbar-collapse.collapse.show,
    .navbar-collapse.collapsing {
        padding-top: $navbar-padding-y;
    }
}

.standard {
    .navbar-collapse,
    .navbar-toggler {
        display: none;
        visibility: hidden;
    }
}

.bauelemente {
    .nav-fill .nav-item {
        flex: none;
    }
}