/** Switches
****************************************************************/

$enable-rounded: false;
$border-radius: 1rem;
$enable-gradients: true;
$link-decoration: none;
$link-hover-decoration: none;

/** Grid
****************************************************************/

$grid-gutter-width: 30px;
$box_typ_01_padding: $grid-gutter-width !default;

/** Colors
****************************************************************/

$theme-colors: (
    'bauelemente': #1b365d,
    'vollholzhaus': #65584f,
    'light': #fff,
    'dark': #333
);

$body-bg: map-get($map: $theme-colors, $key: 'light');
$body-color: map-get($map: $theme-colors, $key: 'dark');

/** Navbar
****************************************************************/
$navbar-padding-y: $grid-gutter-width;
$navbar-padding-x: $grid-gutter-width;

$dropdown-border-width: 0;

// $dropdown-link-active-color: map-get($theme-colors, 'dark');
// $dropdown-link-active-bg: red;

// $navbar-dark-color: map-get($theme-colors, 'light');
// $navbar-dark-active-color: $body-color;

/** Fonts
****************************************************************/

$font-family-base: 'Jost*',
'Helvetica Neue',
Arial,
sans-serif;
$font-size-base: 1.6rem;
$line-height-base: 1.625;
$font-weight-base: 300;
$font-weight-bold: 600;
$paragraph-margin-bottom: 2.4rem;
$fa-font-path: '/files/assets/dist/fonts/fontawesome/';

/** Headlines
****************************************************************/
$h1-font-size: 2.8rem;
$h1-font-color: #1b365d;
$h1-line-height: 3.5rem;
$h1-font-weight: normal;
$h1-font-style: normal;
$h1-margin: ($grid-gutter-width * 2) 0 $grid-gutter-width 0;

$h2-font-size: 2.6rem;
$h2-font-color: #1b365d;
$h2-line-height: 3.3rem;
$h2-font-weight: normal;
$h2-font-style: normal;
$h2-margin: 0 0 $grid-gutter-width 0;

$h3-font-size: 2.6rem;
$h3-font-color: #1b365d;
$h3-line-height: 3.3rem;
$h3-font-weight: normal;
$h3-font-style: normal;
$h3-margin:  0 0 $grid-gutter-width 0;

/** Buttons
****************************************************************/
$btn-padding-x: 18px;
$btn-padding-y: 10px;

$btn-focus-box-shadow: none!important;


/** Forms
****************************************************************/

// $btn-padding-x: 30px;
// $input-focus-box-shadow: none;
// $input-focus-border-color: map-get($pfendt-colors, "primary-bauelemente");

/** Slider
****************************************************************/

// $slick-loader-path: "../../dist/img/";
