@font-face {
    font-family: 'Jost*';
    font-weight: 900;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-900-black.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-900-black.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 900;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-900-blackitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-900-blackitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 800;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-800-heavy.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-800-heavy.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 800;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-800-heavyitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-800-heavyitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: bold;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-700-bold.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-700-bold.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: bold;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-700-bolditalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-700-bolditalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 600;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-600-semi.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-600-semi.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 600;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-600-semiitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-600-semiitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 500;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-500-medium.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-500-medium.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 500;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-500-mediumitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-500-mediumitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: normal;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-400-book.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-400-book.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: normal;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-400-bookitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-400-bookitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 300;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-300-light.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-300-light.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 300;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-300-lightitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-300-lightitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 200;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-200-thin.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-200-thin.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 200;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-200-thinitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-200-thinitalic.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 100;
    font-style: normal;

    src: url('/files/assets/dist/fonts/jost-100-hairline.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-100-hairline.woff') format('woff');
}

@font-face {
    font-family: 'Jost*';
    font-weight: 100;
    font-style: italic;

    src: url('/files/assets/dist/fonts/jost-100-hairlineitalic.woff2') format('woff2'),
    url('/files/assets/dist/fonts/jost-100-hairlineitalic.woff') format('woff');
}
