.box_typ_03 {
    color: map-get($theme-colors, 'light');
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);

    .image_container {
        margin: 0;
    }

    .ce_text {
        padding: $grid-gutter-width;
        margin-bottom: 0;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    h1,
    .h1,
    h2,
    .h2 {
        color: map-get($theme-colors, 'light');
    }

    h2, .h2 {
        margin-bottom: $grid-gutter-width / 2;
    }

    .btn {
        color: map-get($theme-colors, 'light');
        border-color: map-get($theme-colors, 'light');
    }
}

.vollholzhaus {
    .box_typ_03 {
        background: map-get($map: $theme-colors, $key: 'vollholzhaus');

        .btn {
            background: map-get($theme-colors, 'vollholzhaus');

            &:hover {
                border-color: theme-color-level('vollholzhaus', 5);
                background: theme-color-level('vollholzhaus', 5);
            }
        }
    }
}

.bauelemente {
    .box_typ_03 {
        background: map-get($map: $theme-colors, $key: 'bauelemente');

        .btn {
            background: map-get($theme-colors, 'bauelemente');

            &:hover {
                border-color: theme-color-level('bauelemente', 5);
                background: theme-color-level('bauelemente', 5);
            }
        }

        a {
            color: #ffffff;

            &:hover {
                color: #ffffff;
                text-decoration: underline;
            }
        }
    }
}
