.box_typ_02 {
    box-shadow: 0 0 12px rgba(0, 0, 0, .15);

    .image_container {
        margin: 0;
    }

    .ce_text {
        padding: $grid-gutter-width ($grid-gutter-width * 2);

        h2,
        .h2 {
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }
}
