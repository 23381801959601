/**
Highlights
*************************************************************/

.highlight {
    color: map-get($theme-colors, 'light');
    padding: ($grid-gutter-width*1.5) 0;
    margin: 0;

    h1,
    .h1 {
        color: map-get($theme-colors, 'light');

        font-weight: bold;
        line-height: 3.5rem;
        font-size: 3rem;
        margin: 0;

        &:after {
            display: block;
            width: 110px;
            margin: 20px auto 0px auto;
            content: ' ';
            border-top: 2px solid map-get($theme-colors, 'light');
        }
    }

    .ce_text {
        margin-bottom: 0;

        p {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: map-get($map: $theme-colors, $key: 'light');

        &:hover,
        &:focus,
        &:active {
            color: theme-color-level('light', 5) !important;
        }
    }

    @include media-breakpoint-down(sm) { 
        .text--lg {
            display: block;
            margin-bottom: $grid-gutter-width !important;
        }
    }

}

.vollholzhaus {
    .highlight {
        background-color: map-get($map: $theme-colors, $key: 'vollholzhaus');
    }
}

.bauelemente {
    .highlight {
        background-color: map-get($map: $theme-colors, $key: 'bauelemente');
    }
}