/**
Footer
*************************************************************/

#footer {
    margin-top: 60px;
    color: map-get($theme-colors, 'light');
    padding: 50px 0 80px 0;
    background: map-get($theme-colors, 'bauelemente');

    // position: absolute;
    // bottom: 0;
    // width: 100%;

    nav {

        ul,
        li {
            margin: 0;
            padding: 0;

            list-style-type: none;
        }

        a {
            color: map-get($theme-colors, 'light');

            &:hover {
                color: theme-color-level('light', 5);
            }
        }
    }

    .logolink {
        margin-top: -80px;
        margin-bottom: -80px;
        margin-left: -15px;
        padding-top: 60px;

        @include media-breakpoint-down(xs) {
            margin-top: 30px;
            padding-bottom: 60px;
            margin-left: 0;
        }

        p {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width;
        }

        @include media-breakpoint-down(lg) {
            p {
                padding-left: 0;
                padding-right: 0;
            }
        }
        p:last-of-type {
            margin-bottom: 0;
        }

        svg {
            max-width: 195px;
        }
    }
}


.vollholzhaus {
    #footer {
        background: map-get($theme-colors, 'vollholzhaus');
        .logolink {
            background: map-get($map: $theme-colors, $key: 'bauelemente');
            path {
                fill: map-get($map: $theme-colors, $key: 'light')
            }
        }
    }
}

.bauelemente {
    #footer {
        background: map-get($theme-colors, 'bauelemente');
        .logolink {
            background: map-get($map: $theme-colors, $key: 'vollholzhaus');
            path {
                fill: map-get($map: $theme-colors, $key: 'light')
            }
        }
    }
}