#einleitung {
    max-width: 1250px;
    margin: 0 auto ($grid-gutter-width * 2) auto;

    .container-fluid {
        padding: 0;
    }

    .h1 {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    .ce_text {
        font-size: 1.8rem;
        line-height: 2.6rem;

        padding: 30px 20px;
        margin-bottom: 0;

        p {
            margin-bottom: 4rem;

            &:last-of-type {
                margin: 0;
            }
        }
    }

    .image_container {
        margin: 0;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }

        @include media-breakpoint-down(lg) {
            img {
                height: auto;
                width: 100%;
            }
        }
    }
}

.vollholzhaus {
    #einleitung {
        .h1 {
            color: map-get($theme-colors, 'light');
        }

        .ce_text {
            color: map-get($theme-colors, 'light');
            background: map-get($map: $theme-colors, $key: 'vollholzhaus');
        }
    }
}

.bauelemente {
    #einleitung {
        .h1 {
            color: map-get($theme-colors, 'light');
        }

        .ce_text {
            color: map-get($theme-colors, 'light');
            background: map-get($map: $theme-colors, $key: 'bauelemente');
        }
    }
}
