.box_typ_01 {
    transition: opacity 500ms;

    opacity: 0;
    margin-bottom: $grid-gutter-width;

    .inner {
        box-shadow: 0 0 12px rgba(0, 0, 0, .15);
    }

    .image_container {
        margin: 0;
    }

    .headline {
        padding: $box_typ_01_padding;

        @include media-breakpoint-down(lg) {
            // padding-top: $box_typ_01_padding / 2;
            padding-bottom: $box_typ_01_padding / 2;
        }

        h2 {
            margin: 0;
        }
    }

    .content {
        padding: 0 $box_typ_01_padding $box_typ_01_padding $box_typ_01_padding;

        p:last-of-type() {
            margin: 0;
        }
    }

    .link a {
        display: block;

        padding: ($grid-gutter-width / 2) $grid-gutter-width;

        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        text-align: center;
        letter-spacing: .2rem;
        text-transform: uppercase;

        color: map-get($theme-colors, 'light');

        &::after {
            font-family: 'fontawesome';

            content: ' '$fa-var-arrow-circle-right;
        }

        &:hover {
            color: map-get($map: $theme-colors, $key: 'light');
        }
    }
}

.vollholzhaus {
    .box_typ_01 {
        .link a {
            background: map-get($theme-colors, 'vollholzhaus');

            &:hover {
                background: theme-color-level('vollholzhaus', 5);
            }
        }
    }
}

.vollholzhaus.box_typ_01 {
    .link a {
        background: map-get($theme-colors, 'vollholzhaus');

        &:hover {
            background: theme-color-level('vollholzhaus', 5);
        }
    }
}

.bauelemente {
    .box_typ_01 {
        .link a {
            background: map-get($theme-colors, 'bauelemente');

            &:hover {
                background: theme-color-level('bauelemente', 5);
            }
        }
    }
}

.bauelemente.box_typ_01 {
    .link a {
        background: map-get($theme-colors, 'bauelemente');

        &:hover {
            background: theme-color-level('bauelemente', 5);
        }
    }
}