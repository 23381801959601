@import "variables";
@import "fonts";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";
// @import "../../../node_modules/slick-carousel/slick/slick.scss";
// @import "../../../node_modules/slick-carousel/slick/slick-theme.scss";

@import "box_typ_01";
@import "box_typ_02";
@import "box_typ_03";
@import "navigation";
@import "einleitung";
@import "highlight";
@import "footer";

/**
Basics
*************************************************************/
html {
    font-size: 62.5%;
}

html,
body {
    height: 100%;
}


#wrapper {
    padding-top: 125px;
    min-height: 100%;

    @include media-breakpoint-down(md) {
        padding-top: 100px;
    }
}

strong {
    font-weight: $font-weight-bold;
}

#main .mod_article,
#main .distance {
    margin-bottom: ($grid-gutter-width * 2);
}

.img-fluid {
    width: 100%;
}

.invisible {
    display: none;
    visibility: hidden;
}

a {
    color: $body-color;
}

.vollholzhaus a:hover,
.vollholzhaus a:focus,
.vollholzhaus a:active {
    color: map-get($map: $theme-colors, $key: 'vollholzhaus');
}

.bauelemente a:hover,
.bauelemente a:focus,
.bauelemente a:active {
    color: map-get($map: $theme-colors, $key: 'bauelemente');
}

h1,
.h1 {
    line-height: $h1-line-height;
    color: $h1-font-color;
    font-weight: $h1-font-weight;
    font-style: $h1-font-style;
    margin: 0 0 $h1-margin 0;

    @include media-breakpoint-down(lg) {
        margin-bottom: $grid-gutter-width;
    }
}

body.standard {
    h1 {
        margin-top: ($grid-gutter-width);
        margin-bottom: 0;
    }
}

h2,
.h2 {
    line-height: $h2-line-height;
    color: $h2-font-color;
    font-weight: $h2-font-weight;
    font-style: $h2-font-style;
    margin: $h2-margin;

    @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
    }
}

h3,
.h3 {
    line-height: $h3-line-height;
    color: $h3-font-color;
    font-weight: $h3-font-weight;
    font-style: $h3-font-style;
    margin: $h3-margin;
}

/**
Buttons
*************************************************************/

.btn {
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    @include media-breakpoint-up(lg) {
        margin-top: $grid-gutter-width;
    }

    min-width: 220px;
    border-width: 2px;

    &::after {
        content: " "$fa-var-arrow-circle-right;
        font-family: "fontawesome";
    }

    &:hover {
        color: #fff !important;
    }
}

.pagination {
    .btn {
        min-width: 0;
        border: none;

        &:hover {
            border: none;
        }

        &::after {
            display: none;
        }

    }
}

.vollholzhaus {
    .pagination {
        .btn {
            &:hover {
                background-color: map-get($map: $theme-colors, $key: 'vollholzhaus') !important;
            }
        }

        .active {
            .btn {
                background-color: map-get($map: $theme-colors, $key: 'vollholzhaus') !important;
                color: #fff !important;
            }
        }
    }
}

.bauelemente {
    .pagination {
        .btn {
            &:hover {
                background-color: map-get($map: $theme-colors, $key: 'bauelemente') !important;
            }
        }

        .active {
            .btn {
                background-color: map-get($map: $theme-colors, $key: 'bauelemente') !important;
                color: #fff !important;
            }
        }
    }
}


/**
Text
*************************************************************/
.text--lg {
    font-weight: normal;
    line-height: 3.6rem;
    font-size: 2.8rem;

    @include media-breakpoint-down(md) {
        font-size: 2.4rem;
        line-height: 3.2rem;
    }

}

.ce_text {

    .columns {
        @include media-breakpoint-up(lg) {
            column-count: 2;
            column-gap: $grid-gutter-width;
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 2;

        p:last-of-type {
            margin-bottom: 0;
        }
    }

}

/**
Bilder
*************************************************************/
.ce_gallery {
    .image_container {
        margin-bottom: $grid-gutter-width;
    }
}

.carousel-item {
    .image_container {
        margin-bottom: 0;
    }
}

.image_container {
    overflow: hidden;

    a {

        img {
            transition: transform .8s;
        }

        &:hover {
            img {
                transform: scale(1.05); // rotate(2deg);
            }
        }
    }
}

/**
Navigation
*************************************************************/